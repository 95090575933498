<template>
  <div class="row">
    <div class="col-xl-12 col-lg-12">
      <div>
        <div class="card-body">
          <div class="row" style="background-color: #f3fdff">
            <div class="col-3 container">
              <img
                src="resources/images/medisync-big.svg"
                alt="Medisync"
                height="50px"
              />
            </div>
            <div class="col-6 header-text container">Master Kelas Kamar</div>
            <div class="col-3 container">
              {{ name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="row px-4 mb-4">
        <div class="col-6 col-md-4 col-xl-2 p-0 pr-md-3">
          <a-button
            type="primary"
            class="w-100"
            style="height: 31.5px"
            @click="handleAddExamination"
          >
            <div class="d-flex align-items-center justify-content-center">
              <img
                src="resources/images/plus-icon2.svg"
                alt="Icon"
                style="height: 16px; width: 16px"
              />
              <span class="ml-1">Tambah Kelas Kamar</span>
            </div>
          </a-button>
        </div>
        <!-- <div class="col-4 p-0 pr-3">
          <a-select
            ref="select"
            style="width: 100%"
            v-model="selectedCategory"
            @change="handleChangeCategories"
          >
            <a-select-option value="" key="">
              <div class="d-flex align-items-center">
                <img
                  src="resources/images/filter-icon.svg"
                  alt="Icon"
                  style="height: 16px; width: 16px"
                />
                <span class="ml-2">Pilih Kategori Lab</span>
              </div>
            </a-select-option>
            <a-select-option
              v-for="(category, idx) in categories"
              :value="category.name"
              :key="idx"
            >
              {{ category.name }}
            </a-select-option>
          </a-select>
        </div> -->
        <div class="col-12 col-md-8 col-xl-10 p-0 mt-3 mt-md-0">
          <a-input
            placeholder="Ketik nama kelas kamar untuk mencari..."
            @input="filterKamar"
          />
        </div>
      </div>

      <a-table
        :dataSource="filteredDataKamar"
        :columns="columnsKamar"
        rowKey="id"
        style="overflow-x: scroll"
      >
        <template slot="status" slot-scope="record">
          <div
            class="d-flex align-items-center justify-content-center"
            style="border-radius: 20px; background-color: #e1fbea; width: 50px"
            v-if="record.status"
          >
            <p class="m-0" style="color: #16b364">Aktif</p>
          </div>
          <div
            class="d-flex align-items-center justify-content-center"
            style="border-radius: 20px; background-color: #eaecf0; width: 80px"
            v-else
          >
            <p class="m-0" style="color: #475467">Non-Aktif</p>
          </div>
        </template>
        <template slot="actions" slot-scope="record">
          <div class="d-flex justify-content-start">
            <button
              class="mr-2 d-flex align-items-center justify-content-center btn btn-outline-primary edit-button"
              style="width: 100px; border-radius: 26px"
              @click.prevent="goToDetail(record)"
            >
              <img
                src="/resources/images/edit.svg"
                alt="edit"
                class="icon-default"
              />
              <img
                src="/resources/images/edit-hover.svg"
                alt="edit-hover"
                class="icon-hover"
              />
              <span class="ml-2">Ubah</span>
            </button>
            <button
              v-if="record.status"
              @click.prevent="
                handleToggleStatus(record.id, record.name, 'inactive')
              "
              class="d-flex align-items-center justify-content-center btn btn-outline-danger delete-button"
              style="width: 150px; border-radius: 26px"
            >
              <a-icon type="close" />
              <span class="ml-2">Nonaktifkan</span>
            </button>
            <button
              v-else
              @click.prevent="
                handleToggleStatus(record.id, record.name, 'active')
              "
              class="d-flex align-items-center justify-content-center btn btn-outline-warning delete-button"
              style="width: 110px; border-radius: 26px"
            >
              <a-icon type="check" />
              <span class="ml-2">Aktifkan</span>
            </button>
          </div>
        </template>
      </a-table>
    </div>
    <a-modal
      v-model="modalDeleteVisible"
      :title="`${
        selectedDeleteStatus === 'active' ? 'Nonaktifkan' : 'Aktifkan kembali'
      } kelas kamar?`"
      centered
      :footer="null"
    >
      <p>
        {{
          selectedDeleteStatus === "active"
            ? `Setelah dinonaktifkan, kelas kamar ini tidak akan muncul pada opsi kelas kamar saat Anda menambahkan/mengubah data kamar. Anda dapat mengaktifkan kembali kelas kamar nanti.`
            : "Setelah diaktifkan kembali, kelas kamar ini akan muncul pada opsi kelas kamar saat Anda menambahkan/mengubah data kamar."
        }}
      </p>
      <div class="d-flex justify-content-end mt-4">
        <button
          class="mr-2 d-flex align-items-center justify-content-center btn btn-outline-primary edit-button"
          style="width: 50%; border-radius: 26px"
          @click.prevent="handleCloseModal"
        >
          <span class="ml-2">Kembali</span>
        </button>
        <button
          @click.prevent="handleStatus"
          class="d-flex align-items-center justify-content-center btn btn-primary delete-button"
          style="width: 50%; border-radius: 26px"
        >
          <span class="ml-2">Ya</span>
        </button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {
  getRoomClass,
  activateRoomClass,
  deactivateRoomClass,
} from "@/services/axios/api/rooms"

import {} from "@ant-design/icons-vue"

export default {
  data() {
    return {
      name: "",
      filterText: "", // State for the filter input
      dataSource: [],
      filteredDataSource: [],
      columns: [
        {
          title: "No",
          dataIndex: "key",
          key: "no",
          width: "50px",
        },
        {
          title: "Kategori Lab",
          dataIndex: "ExaminationCategory.name",
          key: "ExaminationCategory.name",
          width: "200px",
        },
        {
          title: "Nama Pemeriksaan",
          dataIndex: "name",
          key: "id",
        },
        {
          title: "Aksi",
          key: "actions",
          scopedSlots: { customRender: "actions" },
          width: "200px",
        },
      ],
      columnsKamar: [
        {
          title: "No",
          dataIndex: "No",
          key: "no",
          width: "50px",
        },
        {
          title: "Nama Kelas Kamar",
          dataIndex: "name",
          key: "name",
          width: "150px",
        },
        {
          title: "Tarif Kelas Kamar",
          dataIndex: "tarifKelasKamarUmum",
          key: "tarifKelasUmum",
          width: "150px",
        },
        {
          title: "Tarif Perawatan",
          dataIndex: "tarifPerawatanUmum",
          key: "tarifPerawatanUmum",
          width: "150px",
        },
        {
          title: "BHP Ruangan",
          dataIndex: "bhpRuangan",
          key: "bhpRuangan",
          width: "150px",
        },
        {
          title: "Biaya Administrasi",
          dataIndex: "biayaAdministrasi",
          key: "biayaAdministrasi",
          width: "150px",
        },
        {
          title: "Status",
          key: "status",
          // align: "center",
          width: "100px",
          // alignItem: "center",
          scopedSlots: { customRender: "status" },
        },
        {
          title: "Aksi",
          key: "actions",
          scopedSlots: { customRender: "actions" },
          width: "100px",
        },
      ],
      dataKamar: [
        {
          key: 1,
          name: "Kelas I",
          tarifKelasUmum: "Rp.120.000,00",
          tarifPerawatanUmum: "Rp.120.000,00",
          bhpRuangan: "Rp.120.000,00",
          biayaAdministrasi: "Rp.120.000,00",
          status: "Aktif",
        },
        {
          key: 2,
          name: "Kelas II",
          tarifKelasUmum: "Rp.350.000,00",
          tarifPerawatanUmum: "Rp.30.000,00",
          bhpRuangan: "Rp.120.000,00",
          biayaAdministrasi: "Rp.120.000,00",
          status: "Non-Aktif",
        },
      ],
      filteredDataKamar: [
        {
          key: 1,
          name: "Kelas I",
          tarifKelasUmum: "Rp.120.000,00",
          tarifPerawatanUmum: "Rp.120.000,00",
          bhpRuangan: "Rp.120.000,00",
          biayaAdministrasi: "Rp.120.000,00",
          status: "Aktif",
        },
        {
          key: 2,
          name: "Kelas II",
          tarifKelasUmum: "Rp.350.000,00",
          tarifPerawatanUmum: "Rp.30.000,00",
          bhpRuangan: "Rp.120.000,00",
          biayaAdministrasi: "Rp.120.000,00",
          status: "Non-Aktif",
        },
      ],
      categories: [],
      selectedCategory: "",
      modalDeleteVisible: false,
      selectedDeleteId: "",
      selectedDeleteName: "",
      selectedDeleteStatus: "",
    }
  },
  created() {
    this.getName()
    this.fetchData()
  },
  methods: {
    formatCurrency(number) {
      // Convert the number to a string and use the locale-specific formatting
      const formattedNumber = number.toLocaleString("id-ID")
      // Return the formatted string with 'Rp.' prefix
      return `Rp.${formattedNumber}`
    },
    getName() {
      const name = localStorage.getItem("name")
      this.name = name
    },
    async fetchData() {
      try {
        const { data } = await getRoomClass()
        console.log(data.data, "inigetroomclas")
        this.dataKamar = data.data.map((e, idx) => {
          return {
            ...e,
            No: idx + 1,

            bhpRuangan: this.formatCurrency(e.bhpRuangan),
            biayaAdministrasi: this.formatCurrency(e.biayaAdministrasi),
            tarifKelasKamarUmum: this.formatCurrency(e.tarifKelasKamarUmum),
            tarifPerawatanUmum: this.formatCurrency(e.tarifPerawatanUmum),
          }
        })
        this.filteredDataKamar = data.data.map((e, idx) => {
          return {
            ...e,
            No: idx + 1,

            bhpRuangan: this.formatCurrency(e.bhpRuangan),
            biayaAdministrasi: this.formatCurrency(e.biayaAdministrasi),
            tarifKelasKamarUmum: this.formatCurrency(e.tarifKelasKamarUmum),
            tarifPerawatanUmum: this.formatCurrency(e.tarifPerawatanUmum),
          }
        })
      } catch (error) {
        console.error("Error fetching data:", error)
      }
    },
    filterKamar(e) {
      const filterValue = e.target.value.toLowerCase()
      this.filteredDataKamar = this.dataKamar.filter((item) =>
        item.name.toLowerCase().includes(filterValue),
      )
    },
    handleChangeCategories(selectedCategory) {
      this.filteredDataSource = selectedCategory
        ? this.dataSource.filter(
            (item) => item.ExaminationCategory.name === selectedCategory,
          )
        : [...this.dataSource]
    },
    handleAddExamination() {
      this.$router.push("/kelas-kamar/tambah")
    },
    goToDetail(record) {
      // console.log(record, "<<<")
      this.$router.push(`/kelas-kamar/${record.id}`)
    },
    async handleToggleStatus(id, name, status) {
      this.selectedDeleteId = id
      this.selectedDeleteName = name

      this.selectedDeleteStatus = status

      this.modalDeleteVisible = !this.modalDeleteVisible
    },
    handleCloseModal() {
      this.modalDeleteVisible = false
    },
    async handleStatus() {
      try {
        if (this.selectedDeleteStatus === "active") {
          await activateRoomClass(this.selectedDeleteId)
        } else {
          await deactivateRoomClass(this.selectedDeleteId)
        }

        this.fetchData()

        this.$notification.success({
          message: "Berhasil",
          description: `Data Master Pemeriksaan berhasil di${
            this.selectedDeleteStatus === "active" ? "aktif" : "nonaktif"
          }kan`,
          duration: 5,
        })
        this.modalDeleteVisible = false
      } catch (error) {
        console.error("Error deleting data:", error)
      }
    },
  },
}
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
}

.header-text {
  font-size: 18px;
  text-align: center;
  color: #428a94;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 16px;
  }
}

.delete-button .icon-default {
  display: inline;
}

/* Hover icon disembunyikan secara default */
.delete-button .icon-hover {
  display: none;
}

/* Saat tombol di-hover */
.delete-button:hover .icon-default {
  display: none;
}

.delete-button:hover .icon-hover {
  display: inline;
}

/* Default icon terlihat */
.edit-button .icon-default {
  display: inline;
}

/* Hover icon disembunyikan secara default */
.edit-button .icon-hover {
  display: none;
}

/* Saat tombol di-hover */
.edit-button:hover .icon-default {
  display: none;
}

.edit-button:hover .icon-hover {
  display: inline;
}
</style>
